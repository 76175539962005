<template>
  <v-card class="elevation-0">
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="4">
        <v-toolbar class="elevation-0">
          <div class="heading1 PrimaryFontColor">Connectivity Reports</div>
        </v-toolbar>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="8">
        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-dialog
            ref="dialog_start_date"
            v-model="model_start_date"
            :return-value.sync="start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                label="Start Date"
                prepend-inner-icon="mdi-calendar"
                dense
                outlined
                class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :max="end_date" v-model="start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="model_start_date = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog_start_date.save(start_date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog_end_date"
            v-model="model_end_date"
            :return-value.sync="end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                dense
                outlined
                class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" :min="start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="model_end_date = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog_end_date.save(end_date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-btn small color="primary" @click="GetAllConnectivityReportsMethod(start_date, end_date)">Ok</v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-card-text class="text-center">
      <v-data-table dense class="elevation-0" :headers="connectivityHeaders" :items="GetAllConnectivityReportsList">
        <template v-slot:[`item.low_connection_time_stamp`]="{ item }">
          <div class="fontSize">{{ new Date(item.low_connection_time_stamp).toLocaleString("en-In") }}</div>
        </template>
        <template v-slot:[`item.high_connection_time_stamp`]="{ item }">
          <div class="fontSize">{{ new Date(item.high_connection_time_stamp).toLocaleString("en-In") }}</div>
        </template>
        <template v-slot:[`item.Duration`]="{ item }">
          <div class="fontSize">
            {{ convertTimeToHRMinSec(item.high_connection_time_stamp - item.low_connection_time_stamp) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { GetAllConnectivityReports } from "@/mixins/GetAllConnectivityReports.js";
export default {
  mixins: [GetAllConnectivityReports],
  data: () => ({
    start_date: "",
    end_date: "",
    model_start_date: false,
    model_end_date: false,
    connectivityHeaders: [
      { text: "Lost", value: "low_connection_time_stamp" },
      { text: "Regained", value: "high_connection_time_stamp" },
      { text: "Duration", value: "Duration" },
    ],
    connectivityItems: [],
  }),
  computed: {},
  mounted() {
    let time_stamp = [new Date().getTime()] / 1000 - [86400 * 30];
    this.start_date = new Date(time_stamp * 1000).toISOString().split("T")[0];
    this.end_date = new Date().toISOString().split("T")[0];
    this.GetAllConnectivityReportsMethod(this.start_date, this.end_date);
  },
  methods: {
    convertTimeToHRMinSec(time) {
      let value = time;
      this.hour = Math.floor(value / 1000 / 60 / 60);
      value -= this.hour * 1000 * 60 * 60;
      this.minute = Math.floor(value / 1000 / 60);
      value -= this.minute * 1000 * 60;
      this.seconds = Math.floor(value / 1000);
      return this.addZero(this.hour) + ":" + this.addZero(this.minute) + ":" + this.addZero(this.seconds);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
};
</script>
