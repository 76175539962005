import { API } from "aws-amplify";
export const GetAllConnectivityReports = {
  data() {
    return {
      GetAllConnectivityReportsList: [],
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetAllConnectivityReportsMethod(start_date, end_date) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "listConnectivityLogs",
          organization_id: this.$store.getters.get_asset_object.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          start_date: Number(start_date.replaceAll("-", "")),
          end_date: Number(end_date.replaceAll("-", "")),
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            self.GetAllConnectivityReportsList = response.data.items;
          } else {
            this.GetAllConnectivityReportsList = [];
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
